import React from "react";
import axios from "axios";
import AdminTable from "../../Components/AdminTable";

function Admin() {
  // const classes = useStyle();

  const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_LINKS}`;

  const [endPoints, setEndpoints] = React.useState(null);

  const [reload, setReload] = React.useState(false);
  const [data, setData] = React.useState([]);

  // let data = []
  async function getData() {
    if (endPoints) {
      var data = endPoints.map(
        async (ele) =>
          await axios.get(`${process.env.REACT_APP_API_URL}/${ele.endpoint}`, {
            headers: {
              'x-api-key': `${process.env.REACT_APP_APIKEY}`
            }
          })
      );
      const output = await Promise.all(data);
      data = output;
    }
  }

  React.useEffect(() => {
    console.log("Process..", process.env);
    axios.get(baseURL, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then(async (response) => {
      // console.log(response.data)
      setEndpoints(response.data);

      let calls = response.data.map(
        async (ele) =>
          (await axios.get(`${process.env.REACT_APP_API_URL}/${ele.endpoint}`, {
            headers: {
              'x-api-key': `${process.env.REACT_APP_APIKEY}`
            }
          }))
            .data[0]
      );
      setData(await Promise.all(calls));

      // getData()
    });
  }, []);

  // console.log("yhan..",data)

  return (
    <div>
      {data ? (
        <AdminTable
          linksData={data}
          endPoints={endPoints}
          tableName={"Admin"}
          reload={reload}
          baseURL={baseURL}
          setReload={setReload}
        />
      ) : null}
    </div>
  );
}

export default Admin;
