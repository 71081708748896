import React, { useState } from "react";

import {
  PersonOutline,
  Fingerprint,
  VisibilityOff,
  Visibility,
  Image,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSignIn } from "../../Redux/Slices/authSlice";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Error401 from '../../Assets/page-under-maintenance.png';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

function Maintenance() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [loginErr, setLoginErr] = useState(false)

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Container
        style={{
          height: "100vh",
          // overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          style={{
            alignItems: "center",
            // border: "solid 1px",
            borderRadius: "5px",
            // padding: "5%",
          }}
        >
          <img src={Error401} alt="Logo" />
        </Paper>
      </Container>
    </>
  );
}

export default Maintenance;
