import {
  Container,
  CssBaseline,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Facebook, Instagram, Twitter } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "whitesmoke",
          padding: "5%",
          paddingLeft: "50PX",
        }}
      >
        <CssBaseline />
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <Link
                    href=""
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Typography component="div" variant="caption">
                      <h2>Alrium</h2>
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Typography component="div">
                    Vajra v1.0
                  </Typography>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <List>
                <ListItem>
                  <Typography
                    component="div"
                    variant="caption"
                    style={{ lineHeight: "3px" }}
                  >
                    <h4>Links</h4>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Link
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      lineHeight: "15px",
                    }}
                  >
                    About Us
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      lineHeight: "15px",
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <List>
                <ListItem>
                  <Typography
                    component="div"
                    variant="caption"
                    style={{ lineHeight: "3px" }}
                  >
                    <h4>Help</h4>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Link
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      lineHeight: "15px",
                    }}
                  >
                    Support
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      lineHeight: "15px",
                    }}
                  >
                    Contact Us
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "5%" }}>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton color="primary" component="label">
                <Facebook style={{ marginLeft: "2%", marginRight: "2%" }} />
              </IconButton>
              <IconButton color="primary" component="label">
                <Twitter style={{ marginLeft: "2%", marginRight: "2%" }} />
              </IconButton>
              <IconButton color="primary" component="label">
                <Instagram style={{ marginLeft: "2%", marginRight: "2%" }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              order={2}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography component="div" variant="caption">
                © Alrium, 2023. Copyright reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
