import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "../../Components/CustomTable";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { read, utils, writeFile } from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DynamicTable({ endpoint, tableName }) {
  // const classes = useStyles();

  const baseURL = `${process.env.REACT_APP_API_URL}/${endpoint}`;

  // const baseURL = `http://localhost:3000/${endpoint}`;

  const [data, setData] = React.useState(null);
  const [dataOrg, setDataOrg] = React.useState(null);
  const [dataOrg2, setDataOrg2] = React.useState(null);

  // const ButtonStyle = { margin: "0px 10px" };
  const [reload, setReload] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [column, setColumns] = React.useState();
  const [firstColumn, setFirstColumn] = React.useState();
  const [keys, setKeys] = React.useState();
  const [lastId, setLastId] = React.useState();
  const [filter, setFilter] = React.useState({})
  const [totalValues, setTotalValues] = React.useState({})
  const [sortAsc, setSortAsc] = React.useState(false);

  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ]

  function formatDateTime(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return ('0' + date.getDate()).slice(-2) + '-' + (monthNames[date.getMonth()]) + '-' + date.getFullYear() + " " + formatTime(date.getHours() + ":" + date.getMinutes());
    }
  }

  const [imoprtedData, setImportedData] = React.useState([])

  const addNewData = async (data) => {
    // console.log(data)
    // data.id=lastId+1
    await axios
      .post(`${baseURL}`, data, {
        headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`
        }
      })
      .then((response) => {
        // setLastId(response.data.id)
      })
      .catch((err) => console.log(err))
  }

  const updateBulkData = async (data, id) => {
    await axios
      .patch(`${baseURL}/${id}`, data, {
        headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`
        }
      })
      .then((response) => {
        console.log(response)
      })
      .catch((err) => {
        // if(err.response.status==404){
        //  addNewData(data)
        // }
        console.log(err)
      })
  }

  function hasSubArray(master, sub) {
    return sub.every((i => v => i = master.indexOf(v, i) + 1)(0));
  }

  const handleImport = ($event) => {
    setIsLoading(true)
    const files = $event.target.files;
    console.log("coming here..")
    if (files.length) {
      const file = files[0];
      // console.log(file)
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        let columnName = []

        column.map(ele => {
          if (ele == 0) {
            columnName.push(ele)
          } else {
            columnName.push(ele.name)
          }
        })

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { raw: false })
          const fileColumns = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })
          // let fileColumns = Object.keys(rows[0])
          // console.log(fileColumns[0].map(String).sort(), columnName.map(String).sort())
          if (hasSubArray(columnName.map(String).sort(), fileColumns[0].map(String).sort())) {
            // console.log(firstColumn)
            rows.map((i, index) => {
              Object.keys(firstColumn).forEach(ele => {
                if (ele == 'id') {
                  rows[index]['id'] = i[firstColumn[ele]]
                  delete rows[index][0]
                } else if (firstColumn[ele].type == 'array') {
                  let key = firstColumn[ele]['name']
                  rows[index][ele] = i[key].split(',')
                  if (key != ele) {
                    delete rows[index][key]
                  }
                } else if (firstColumn[ele].type == 'date' || firstColumn[ele].type == 'datetime') {
                  let key = firstColumn[ele]['name']
                  // console.log("rows-->",key, ele, rows[index][ele], i[key])
                  i[key] ? rows[index][ele] = new Date(i[key]) : rows[index][ele] = ""
                  if (key != ele) {
                    delete rows[index][key]
                  }
                }
                else {
                  let key = firstColumn[ele]['name']
                  rows[index][ele] = i[key]
                  if (key != ele) {
                    delete rows[index][key]
                  }
                }
              })
            })
            let lastIds = lastId
            // console.log(rows)
            rows.map(ele => {
              // console.log(ele, ele.id)
              if (ele.id == undefined) {
                lastIds += 1
                ele.id = lastIds
                addNewData(ele)
              } else {
                updateBulkData(ele, ele.id)
              }
            })
            toast("File imported successfully!")
            // setIsLoading(false)
            setTimeout(() => {
              setIsLoading(false)
            }, 1000)
            setTimeout(() => {
              window.location.reload()
            }, 1500)
            // console.log(rows)
            // setImportedData(rows)
          } else {
            toast("Wrong file or incorrect columns!")
            setTimeout(() => {
              setIsLoading(false)
            }, 1000)
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          }

        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  function search(user) {
    return Object.keys(this).every((key) => {
      // console.log("key ..",key)
      let currentType = column.find((ele) => ele.type == firstColumn[key].type)
      // console.log("currenttype-->", currentType)
      // console.log("data..", String(user[key]).toLowerCase(), String(this[key]).toLowerCase(), String(user[key])?.toLowerCase()[0], String(this[key])?.toLowerCase()[0])
      if (key == 'id') {
        if (this[key] == '') {
          return true
        } else {
          return String(user[key.toLowerCase()]).toLowerCase().includes(String(this[key]).toLowerCase()) && String(user[key])?.toLowerCase()[0] == String(this[key])?.toLowerCase()[0]
        }
      } else if (currentType.type == 'date' && this[key] != '') {
        if (String(this[key])[0] == '-') {
          return String(formatDateTime(user[key])).toLowerCase().includes(String(this[key]).toLowerCase())
        } else {
          return String(formatDateTime(user[key])).toLowerCase().includes(String(this[key]).toLowerCase()) && String(formatDateTime(user[key]))?.toLowerCase()[0] == String(this[key])?.toLowerCase()[0]
        }
      } else if (currentType.type == 'datetime' && this[key] != '') {
        if (String(this[key])[0] == '-') {
          return String(formatDateTime(user[key])).toLowerCase().includes(String(this[key]).toLowerCase())
        } else {
          return String(formatDateTime(user[key])).toLowerCase().includes(String(this[key]).toLowerCase()) && String(formatDateTime(user[key]))?.toLowerCase()[0] == String(this[key])?.toLowerCase()[0]
        }
      }
      else if (this[key] != '') {
        return String(user[key]).toLowerCase().includes(String(this[key]).toLowerCase()) && String(user[key])?.toLowerCase()[0] == String(this[key])?.toLowerCase()[0]
      } else if (this[key] == '') {
        return true
      }
    })
  }

  const updateTotalColumn = () => {
    console.log("coming here..")
    // setReload(true)
    if (totalValues != {}) {
      const result = Object.keys(totalValues)
      // console.log("keys of saved data -->", newData)
      const updatedState = {};
      result.map(ele => {
        console.log(excelData)
        const sum = excelData.reduce((accumulator, object) => {
          if (object[ele] == undefined) {
            return Number(accumulator) + 0
          }
          return Number(accumulator) + Number(object[ele])
        }, 0);
        console.log("updated sum", ele, sum)
        updatedState[ele] = sum
        // if (!isNaN(sum)) {
        // console.log("updated total values-->", totalValues)
        setTotalValues({
          ...totalValues,
          ...updatedState,
        });
        // }
      })
      // console.log(totalValues)
    }
  }

  function dynamicSort(property, dataType) {
    console.log("propery--", dataType)
    var sort_order = 1;
    if (sortAsc === true) {
      sort_order = -1;
    }
    if (dataType == "date" || dataType == "datetime") {
      return function (a, b) {
        // a should come before b in the sorted order
        if (!a[property]) {
          return -1 * sort_order
        }
        if (!b[property]) {
          return 1 * sort_order
        }
        if (new Date(a[property]) < new Date(b[property])) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (new Date(a[property]) > new Date(b[property])) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }
    } else if (dataType == "number" || dataType == "float" || property == "id") {
      return function (a, b) {
        // a should come before b in the sorted order
        if (!a[property]) {
          return -1 * sort_order
        }
        if (!b[property]) {
          return 1 * sort_order
        }
        if (Number(a[property]) < Number(b[property])) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (Number(a[property]) > Number(b[property])) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }
    } else {
      return function (a, b) {
        // a should come before b in the sorted order
        if (!a[property]) {
          return -1 * sort_order
        }
        if (!b[property]) {
          return 1 * sort_order
        }
        if (a[property].toString().toLowerCase() < b[property].toString().toLowerCase()) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (a[property].toString().toLowerCase() > b[property].toString().toLowerCase()) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }
    }
  }

  const onSort = (key, setNewData, dataType) => {
    // console.log(dataOrg)
    let result = dataOrg.sort(dynamicSort(key, dataType))
    // setDataOrg(srchData)
    // console.log(result)
    setExcelData(result)
    setDataOrg2(result)
    setData(result)
    setNewData(result)
  }

  const onSearch = (srchTxt, key, setNewData, updateTotalColumn, setNewExcelData) => {
    // console.log(srchTxt, key)
    // console.log(filter)
    filter[key] = srchTxt
    if (srchTxt.length > 0 || srchTxt == '') {
      // let srchData = dataOrg.filter((ele)=> ele[key]==srchTxt)
      // console.log("filter-->", filter)
      var result = dataOrg.filter(search, filter);

      // console.log(srchData)
      setData(result)
      // setDataOrg(srchData)
      setNewData(result)
      setNewExcelData(result)
      setExcelData(result)
      setDataOrg2(result)
      if (totalValues != {}) {
        const result2 = Object.keys(totalValues)
        // console.log("keys of saved data -->", newData)
        const updatedState = {};
        result2.map(ele => {
          // console.log(result)
          const sum = result.reduce((accumulator, object) => {
            if (object[ele] == undefined) {
              return Number(accumulator) + 0
            }
            return Number(accumulator) + Number(object[ele])
          }, 0);
          console.log("updated sum", ele, sum)
          updatedState[ele] = sum
          // if (!isNaN(sum)) {
          // console.log("updated total values-->", totalValues)
          setTotalValues({
            ...totalValues,
            ...updatedState,
          });
          // }
        })
        // console.log(totalValues)
      }
    } else {
      setNewData(dataOrg)
      setData(dataOrg)
      setDataOrg2(dataOrg)
      updateTotalColumn()
    }
  }

  const [searchKey, setSearchKey] = React.useState([])
  const [searchLbl, setSearchLbl] = React.useState([])
  const [excelData, setExcelData] = React.useState([])
  const [advanceSearch, setAdvanceSearch] = React.useState(false)
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  const handleAdvanceSearch = () => {
    if (tableName == 'Timesheet') {
      setSearchKey([])
      setSearchLbl([])
      setStartDate()
      setEndDate()
      setData(dataOrg)
      setAdvanceSearch(!advanceSearch)
    }
  }

  const onAdvanceSearch = (setNewData, advanceSearchTypeSelected) => {
    // console.log(startDate, endDate)
    if (startDate != undefined && endDate != undefined) {
      if (setNewData == 'clear') {
        setData(dataOrg2)
      } else if (advanceSearchTypeSelected == 'date') {
        let filtered = data.filter((ele) => {
          // console.log(column.find(ele=>ele.type==advanceSearchTypeSelected))
          // console.log(new Date(ele['dateTime']))
          let productDate = new Date(ele["dateTime"])
          return (productDate >= new Date(startDate) &&
            productDate <= new Date(endDate))
        })
        setData(filtered)
        setDataOrg(filtered)
      } else if (advanceSearchTypeSelected == 'datetime') {
        let filtered = data.filter((ele) => {
          // console.log(column.find(ele=>ele.type==advanceSearchTypeSelected))
          // console.log(new Date(ele['dateTime']))
          let productDate = new Date(ele["Timestamp"])
          return (productDate >= new Date(startDate) &&
            productDate <= new Date(endDate))
        })
        setDataOrg(filtered)
        setData(filtered)
      }
      else {
        setData(dataOrg2)
      }
    }else  if (startDate != undefined) {
      if (setNewData == 'clear') {
        setData(dataOrg2)
      } else if (advanceSearchTypeSelected == 'date') {
        let filtered = data.filter((ele) => {
          // console.log(column.find(ele=>ele.type==advanceSearchTypeSelected))
          // console.log(new Date(ele['dateTime']))
          let productDate = new Date(ele["dateTime"])
          return (productDate >= new Date(startDate))
        })
        setData(filtered)
        setDataOrg(filtered)
      } else if (advanceSearchTypeSelected == 'datetime') {
        let filtered = data.filter((ele) => {
          // console.log(column.find(ele=>ele.type==advanceSearchTypeSelected))
          // console.log(new Date(ele['dateTime']))
          let productDate = new Date(ele["Timestamp"])
          return (productDate >= new Date(startDate) &&
            productDate <= new Date(endDate))
        })
        setDataOrg(filtered)
        setData(filtered)
      }
      else {
        setData(dataOrg2)
      }
    }else  if (endDate != undefined) {
      if (setNewData == 'clear') {
        setData(dataOrg2)
      } else if (advanceSearchTypeSelected == 'date') {
        let filtered = data.filter((ele) => {
          // console.log(column.find(ele=>ele.type==advanceSearchTypeSelected))
          // console.log(new Date(ele['dateTime']))
          let productDate = new Date(ele["dateTime"])
          return ( productDate <= new Date(endDate))
        })
        setData(filtered)
        setDataOrg(filtered)
      } else if (advanceSearchTypeSelected == 'datetime') {
        let filtered = data.filter((ele) => {
          // console.log(column.find(ele=>ele.type==advanceSearchTypeSelected))
          // console.log(new Date(ele['dateTime']))
          let productDate = new Date(ele["Timestamp"])
          return ( productDate <= new Date(endDate))
        })
        setDataOrg(filtered)
        setData(filtered)
      }
      else {
        setData(dataOrg2)
      }
    }
  }

  React.useEffect(() => {
    axios.get(`${baseURL}?_sort=id`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      setFirstColumn(response.data[0])
      // console.log(Object.values(response.data[0]))
      setColumns(Object.values(response.data[0]));
      setKeys(Object.keys(response.data[0]));
      response.data.shift();
      response.data.reverse();
      // console.log(response.data)
      console.log("here...")
      if (response.data.length === 0) {
        setLastId(0);
      } else {
        setLastId(response.data[0].id);
      }
      setData(response.data);
      setExcelData(response.data)
      setDataOrg(response.data)
      setDataOrg2(response.data)
    });
  }, [reload, endpoint, tableName]);

  React.useEffect(() => {
    setSearchKey([])
    setSearchLbl([])
    setAdvanceSearch(false)
    setFilter({})
  }, [tableName]);

  return (
    <>
      <Grid >
        {data && (
          <CustomTable
            data={data}
            excelData={excelData}
            firstColumn={firstColumn}
            tableName={tableName}
            reload={reload}
            setReload={setReload}
            column={column}
            keys={keys}
            baseURL={baseURL}
            lastId={Number(lastId)}
            onSearch={onSearch}
            searchKey={searchKey}
            searchLbl={searchLbl}
            setSearchKey={setSearchKey}
            setSearchLbl={setSearchLbl}
            updateTotalColumn={updateTotalColumn}
            totalValues={totalValues}
            setTotalValues={setTotalValues}
            handleImport={handleImport}
            isLoading={isLoading}
            handleAdvanceSearch={handleAdvanceSearch}
            advanceSearch={advanceSearch}
            setAdvanceSearch={setAdvanceSearch}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onAdvanceSearch={onAdvanceSearch}
            onSort={onSort}
            setSortAsc={setSortAsc}
            sortAsc={sortAsc}
          />
        )}
      </Grid>
      <ToastContainer />
    </>
  );
}

export default DynamicTable;
