import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    email: null,
    password: null,
    name: null,
    isAdmin: false
}

const authSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setSignIn: (state, action) => {
            // console.log(action.payload)
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.password = action.payload.password;
            state.isLoggedIn = action.payload.isLoggedIn;
            state.isAdmin = action.payload.isAdmin;
        },
        setSignOut: (state) => {
            state.password= null
            state.name= null
            state.isAdmin= false
            state.isLoggedIn = false;
        }
    }
});

export const { setSignIn, setSignOut } = authSlice.actions;

export const selectIsLoggedIn = (state) => state?.auth?.isLoggedIn;
export const selectEmail = (state) => state?.auth?.email;
export const selectPassword = (state) => state?.auth?.password;
export const selectName = (state) => state?.auth?.name;
export const selectIsAdmin = (state) => state?.auth?.isAdmin;

export default authSlice.reducer;