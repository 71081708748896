import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  makeStyles,
  styled,
  useTheme,
  Tooltip
} from "@material-ui/core";
import axios from "axios";
import * as React from "react";
import {
  selectIsAdmin,
  selectIsLoggedIn,
  selectName,
  setSignIn,
  setSignOut,
} from "../../Redux/Slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ChevronLeft,
  ChevronLeftOutlined,
  ChevronRight,
  ChevronRightOutlined,
  EventNote,
  Home,
  Menu,
  Notifications,
  Person,
  PersonAdd,
} from "@material-ui/icons";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0e5f6b",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#0e5f6b",
  },
  linkStyle: {
    color: "white",
    "text-decoration": "none",
  },
  drawerLink: {
    color: "black",
    "text-decoration": "none",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("70px")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  signedInUser: {
    padding: theme.spacing(1),
  },
}));

export const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [data, setData] = React.useState([]);

  const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_LINKS}`;
  // const baseURL = " http://localhost:3000/links";

  const baseURLMaintenance = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_MAINTENANCE_URL}`;
  
  const [liveStatus, setLiveStatus] = React.useState("Maintenance")

  const checkMaintenance =()=>{
    axios.get(baseURLMaintenance, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      // console.log("Here-->", response.data)
      let obj = response.data.find(o => o.application === "Vajra");
      // setData(response.data);
      // console.log("hre", obj)
      setLiveStatus(obj.mode)
      if(obj.mode!='Live'){
        window.location.reload(false);
      }
    });
  }

  React.useEffect(() => {
    axios.get(baseURL, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      // console.log(response.data);
      // setColumns(Object.values(response.data[0]))
      // setKeys(Object.keys(response.data[0]))
      // response.data.shift()
      setData(response.data);
    });
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const prevOpen = React.useRef(menuOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = menuOpen;
  }, [menuOpen]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  //Drawer
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let user = false;

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAdmin = useSelector(selectIsAdmin);
  const fullName = useSelector(selectName);

  // console.log("logged in -->", isAdmin);

  const dispatch = useDispatch();

  // console.log(data);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          position="absolute"
          style={{ background: "#0e5f6b"}}
          open={open}
        >
          <Toolbar>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <Menu />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Alrium - Vajra
                <p style={{fontSize: '12px'}}>
                Simple and Efficient
              </p>
              </Typography>
            </div>

            {/* <Link to="/login"  className={classes.linkStyle}> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <div>{`Hello, ${fullName ? fullName : "Guest"}`}</div>
              {isLoggedIn ? (
                <div>
                  <Link to="/vajra-login">
                    <Button
                      onClick={() => dispatch(setSignOut())}
                      color="inherit"
                      style={{ textTransform: "none", color: "white" }}
                    >
                      Logout
                    </Button>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link to="/vajra-login">
                    <Button
                      color="inherit"
                      style={{ textTransform: "none", color: "white" }}
                    >
                      Login
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>
          <Divider />

          {/* Drawer Contents */}

          <List>
            <Link
              to="/"
              className={classes.drawerLink}
              onClick={handleDrawerClose}
            >
              <ListItem onClick={()=> checkMaintenance()} button key="Home">
              <Tooltip title="Home">
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>
            {isAdmin =='Admin' && <Link to="/admin" onClick={handleDrawerClose}
 className={classes.drawerLink}>
             <ListItem onClick={()=> checkMaintenance()} button key="Admin">
             <Tooltip title="Admin">
                <ListItemIcon>
                  <PersonAdd />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Admin" />
              </ListItem>
            </Link>}
            {data.map((ele, index) => {
              return (
                <>
                {ele.isAdmin? isAdmin=='Admin'?
                (
                <Link key={index} to={ele.link} className={classes.drawerLink}  onClick={handleDrawerClose}
                >
                  <ListItem onClick={()=> checkMaintenance()} button key={ele.link}>
                  <Tooltip title={ele.name}>
                    <ListItemIcon>
                      {ele.name === "Users Management" ? (
                        <Person />
                      ) : (
                        <div
                          style={{
                            border: "solid 2px",
                            borderRadius: "5px",
                            padding: "5px 10px 5px 10px",
                            fontSize: "0.75rem",
                            backgroundColor: "rgba(0, 0, 0, 0.54)",
                            color: "#ffffff",
                          }}
                        >
                          {ele.name.charAt(0)}
                        </div>
                      )}
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={ele.name} />
                  </ListItem>
                </Link>): null :
                (
                  <Link key={index} to={ele.link} className={classes.drawerLink} onClick={handleDrawerClose}
                  >
                    <ListItem onClick={()=> checkMaintenance()} button key={ele.link}>
                    <Tooltip title={ele.name}>
                      <ListItemIcon>
                        {ele.name === "Users" ? (
                          <Person />
                        ) : (
                          <div
                            style={{
                              border: "solid 2px",
                              borderRadius: "5px",
                              padding: "5px 10px 5px 10px",
                              fontSize: "0.75rem",
                              backgroundColor: "rgba(0, 0, 0, 0.54)",
                              color: "#ffffff",
                            }}
                          >
                            {ele.name.charAt(0)}
                          </div>
                        )}
                      </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={ele.name} />
                    </ListItem>
                  </Link>)
                }
              </>
              );
            })}
            {/* <Link to="/screen1" className={classes.drawerLink}>
            <ListItem button key="Screen1">
              <ListItemIcon><AirplayIcon /></ListItemIcon>
              <ListItemText primary="Screen 1" />
            </ListItem>
          </Link>
          <Link to="/screen2" className={classes.drawerLink}>
            <ListItem button key="Screen2">
              <ListItemIcon><VideoLabelIcon /></ListItemIcon>
              <ListItemText primary="Screen 2" />
            </ListItem>
          </Link> */}
          </List>
          {/* <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
        </Drawer>

        {/* <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftOutlined />
              ) : (
                <ChevronRightOutlined />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <ListItem button key="Home">
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>
            {isAdmin && <Link to="/admin" style={{ textDecoration: "none", color: "black" }}>
              <ListItem button key="Admin">
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
            </Link>}
            {data.map((ele) => {
              return (
                <>
                 {ele.isAdmin? isAdmin?
                 (<Link
                    to={ele.link}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button key={ele.link}>
                      <ListItemIcon>
                        {ele.name === "List Users" ? (
                          <Person />
                        ) : ele.name === "Notifications" ? (
                          <Notifications />
                        ) : ele.name === "Timesheets" ? (
                          <EventNote />
                        ) : (
                          <PersonAdd />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={ele.name} />
                    </ListItem>
                  </Link>):null : 
                  <Link
                  to={ele.link}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button key={ele.link}>
                    <ListItemIcon>
                      {ele.name === "List Users" ? (
                        <Person />
                      ) : ele.name === "Notifications" ? (
                        <Notifications />
                      ) : ele.name === "Timesheets" ? (
                        <EventNote />
                      ) : (
                        <PersonAdd />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={ele.name} />
                  </ListItem>
                </Link>
                  }
                </>
              );
            })}
          </List>
        </Drawer> */}
      </Box>
    </div>
  );
};
