import React, { useState } from "react";

import {
  PersonOutline,
  Fingerprint,
  VisibilityOff,
  Visibility,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSignIn } from "../../Redux/Slices/authSlice";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import UnAuthorised from "../Unauthorized";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

function Login() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);
  const [loginErr, setLoginErr] = useState(false)


  // const baseURL = " http://localhost:3000/users";

  const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_USERS}`;

  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("cryptssh")


  const handleKeyDown = (event) => {
    console.log(event)
    if (event.key === 'Enter') {
      console.log('do validate')
    }
  }

  const signIn = (e) => {
    if (email != "Username" || password != "Password") {
      let param = { params: { username: email, password: password }, headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`
        } }
      axios.get(baseURL, param).then((response) => {
        let user = response.data[0]
        if (response.data.length > 0) {
          const loggedUser = {
            isLoggedIn: true,
            email: email,
            name: user.fullName,
            mobile: "99999999",
            password: password,
            isAdmin: user.role,
          }
          dispatch(setSignIn(loggedUser));
          navigate("/");
        } else {
          setLoginErr(true)
        }
      }).catch(err => setLoginErr(true))
    } else {
      setLoginErr(true)
    }
  };

  //   for password

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {process.env.REACT_APP_CRYPT == term ? <Container
        style={{
          height: "100vh",
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          style={{
            alignItems: "center",
            border: "solid 1px",
            borderRadius: "5px",
            padding: "5%",
          }}
        >
          <form>
            <h2 style={{ textAlign: "left", marginLeft: '20px' }}>Vajra</h2>
            <Grid container style={{ alignItems: "flex-end" }}>
              <Grid item xs={2} md={2}>
                <PersonOutline className="p-0" />
              </Grid>
              <Grid item xs={10} md={10}>
                <TextField id="email" value={email} label="Username" type="email" className="pt-2" onChange={e => setEmail(e.target.value)} fullWidth autoFocus required />
              </Grid>
            </Grid>
            <Grid container style={{ alignItems: "flex-end" }}>
              <Grid item xs={2} md={2}>
                <Fingerprint className="p-0" />
              </Grid>
              <Grid item xs={10} md={10}>
                <TextField
                  id="password"
                  value={password}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={e => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      signIn()
                    }
                  }}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <Link href="" underline="none">
                <Typography
                  className="text-end"
                  component="div"
                  variant="caption"
                  style={{ textAlign: "end" }}
                >
                  Forgot Password ?
                </Typography>
              </Link>
            </Grid>
            <Grid>
              <FormControl
                item
                xs={6}
                style={{ margintop: "2%", textAlign: "start" }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember Me"
                  component="div"
                  variant="caption"
                />
              </FormControl>
              {loginErr && <Typography
                className="text"
                component="div"
                variant="caption"
                style={{ textAlign: 'center', color: "red" }}
              >
                Incorrect Username or Password!
              </Typography>}
            </Grid>

            <Button
              onClick={signIn}
              variant="outlined"
              color="primary"
              spacing={12}
              style={{
                textTransform: "none",
                alignItems: "flex-end",
                marginTop: " 30px",
              }}
              fullWidth
            >
              Login
            </Button>
          </form>
        </Paper>
      </Container> : <UnAuthorised />}
    </>
  );
}

export default Login;
