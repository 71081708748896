import axios from "axios";
import "./table.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as moment from 'moment'
import _ from 'lodash';

// Import the default CSS
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Layout } from "../LayoutComponent";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  makeStyles,
  withStyles,
  FormControl,
  CircularProgress
} from "@material-ui/core";
import { Add, Delete, EditRounded, Visibility, ExpandMore, ExitToApp, AddBox, Search, Close, ImportExport, SystemUpdateAltOutlined, ImageSearchSharp } from "@material-ui/icons";
import IosShareIcon from '@mui/icons-material/IosShare';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { ToastContainer, toast } from 'react-toastify';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';

import { useMediaQuery } from "@mui/material";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },
  dialog: {
    width: "500px",
  },
  customTableContainer: {
    overflowX: "initial"
  },
  MuiButtoncontainedSecondary: {
    color: '#0E5F6B',
    backgroundColor: '#fff',
    height: '60px',
    marginRight: '10px',
    border: 'solid 3px',
    borderColor: '#0E5F6B',
    borderRadius: 10,
  },
  root: {
    position: "relative",
    width: '100%'
  },
  display: {
    position: "absolute",
    top: 2,
    left: 0,
    bottom: 2,
    background: "white",
    pointerEvents: "none",
    right: 30,
    display: "flex",
    alignItems: "center"
  },
  input: {
    right: 260
  }
});

const theme = createTheme({
  overrides: {
    MuiButton: {
      outlined: {
        border: "solid 3px",
        color: "#0e5f6b",
        borderColor: "#0e5f6b",
        "&:hover": {
          borderColor: "#0e5f6b",
        },
      },
    },
  },
});

function CustomTable({
  data,
  excelData,
  reload,
  setReload,
  tableName,
  column,
  keys,
  baseURL,
  lastId,
  onSearch,
  firstColumn,
  searchKey,
  setSearchKey,
  searchLbl,
  setSearchLbl,
  updateTotalColumn,
  totalValues,
  setTotalValues,
  handleImport,
  isLoading,
  handleAdvanceSearch,
  advanceSearch,
  setAdvanceSearch,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onAdvanceSearch,
  onSort,
  setSortAsc,
  sortAsc
}) {
  const classes = useStyles();
  const location = useLocation();
  // console.log(lastId)

  // for mobileview
  const matches = useMediaQuery("(min-width:600px)");
  // console.log("Matches ---> ", matches);
  const isMobile = matches;
  // console.log("Is mobile ---> ", data);

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(`View ${tableName}`)
  const [showAdd, setShowAdd] = useState(false);
  const [val, setCurrentVal] = useState({});
  const [editedVal, setCurrentEditedVal] = useState({});
  const [saveRestrict, setSaveRestrict] = useState(false)
  const [sumTotal, setSumTotal] = useState(null)
  const [newRequired, setNewRequired] = useState([])
  const [valuesNewAdd, setValuesAdd] = useState({})
  const [error, setError] = useState("");
  const [advanceSearchTypeSelected, setAdvanceSearchSelected] = useState('greaterThan')
  const [advanceSearchDataType, setadvanceSearchDataType] = useState('date')

  const addBtnRef = React.useRef()

  let addedData = {};

  const handleClose = () => {
    setCurrentEditedVal(val)
    setCurrentVal(val)
    setShow(false)
  };
  const handleAddClose = () => {
    addedData = {}
    setValuesAdd({})
    setShowAdd(false)
    setSaveRestrict(false)
  };
  let [requiredData, setRequiredData] = useState([])

  const handleShow = (str, val) => {
    setTitle(str);
    const reuiredData = column.map((ele, index) => {
      if (ele.required != undefined) {
        let val2 = { required: ele.required, value: val[keys[index]] }
        return val2
      } else {
        let val2 = { required: false, value: 0 }
        return val2
      }
    })
    setRequiredData(reuiredData)
    setCurrentEditedVal(val)
    setCurrentVal(val);
    setShow(true);
  };


  const handleAddPopupShow = () => setShow(true);

  var editedNotification = {};

  const onChangeVal = (index, value, key, required) => {
    let val = { required, value }
    requiredData[index] = val
    // console.log(data1)
    editedNotification[key] = value;
  };

  const onAddChangeVal = (index, value, key, required) => {
    // console.log(value)
    setValuesAdd({
      ...valuesNewAdd,
      [key]: value,
    });
    let val = { required, value }
    newRequired[index] = val
  };

  const [newData, setNewData] = useState(data)
  const [newExcelData, setNewExcelData] = useState(excelData)

  const sumTotalColumn = (key) => {
    const sum = data.reduce((accumulator, object) => {
      if (object[key] == undefined) {
        return Number(accumulator) + 0
      }
      return Number(accumulator) + Number(object[key])
    }, 0);
    // setSumTotal(sum)
    // setTotalColumn(columnName)
    if (!isNaN(sum)) {
      setTotalValues({
        ...totalValues,
        [key]: sum,
      });
    }
  }

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ]

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return ('0' + date.getDate()).slice(-2) + '-' + (monthNames[date.getMonth()]) + '-' + date.getFullYear();
    }
  }

  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }

  function formatDateTime(inputDate) {
    var date = new Date(inputDate);
    // console.log(date.getMinutes().length)
    let min = date.getMinutes().toString().length == 1 ? "0" + date.getMinutes() : date.getMinutes()
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      // console.log(date.getDate().length())
      return ('0' + date.getDate()).slice(-2) + '-' + (monthNames[date.getMonth()]) + '-' + date.getFullYear() + " " + formatTime(date.getHours() + ":" + min);
    }
  }

  function InputComponent({ defaultValue, inputRef, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(() => props.value || defaultValue);

    const handleChange = event => {
      setValue(event.target.value)
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.display}>{formatDate(value)}</div>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={new Date(value)}
        />
      </div>
    );
  }

  function InputComponentDateTime({ defaultValue, inputRef, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(() => props.value || defaultValue);

    const handleChange = event => {
      setValue(event.target.value)
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.display}>{formatDateTime(value)}</div>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={new Date(value)}
        />
      </div>
    );
  }
  const [value3, setValue3] = React.useState({});
  const [value4, setValue4] = React.useState({});

  function AddInputComponent({ defaultValue, inputRef, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(() => props.value)
    // console.log(props.value, defaultValue)

    const handleChange = event => {
      // console.log(event.target.value)
      setValue(event.target.value)
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.display}>{value == '01-Nov-2023' ? "" : value}</div>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={new Date(value)}
        />
      </div>
    );
  }

  function AddInputComponentDateTime({ defaultValue, inputRef, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(() => props.value)
    // console.log(props.value, defaultValue)

    const handleChange = event => {
      // console.log(event.target.value)
      setValue(event.target.value)
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.display}>{value == '01-Nov-2023' ? "" : value}</div>        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={new Date(value4)}
        />
      </div>
    );
  }

  function InputComponentStart({ defaultValue, inputRef, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(() => props.value || defaultValue);

    const handleChange = event => {
      setValue(event.target.value)
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.display}>{formatDate(value)}</div>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={(e) => {
            handleChange(e)
            setStartDate(e.target.value)
          }}
          value={new Date(value)}
        />
      </div>
    );
  }

  function InputComponentEnd({ defaultValue, inputRef, ...props }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(() => props.value || defaultValue);

    const handleChange = event => {
      setValue(event.target.value)
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.display}>{formatDate(value)}</div>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={new Date(value)}
        />
      </div>
    );
  }


  const handleShowAdd = () => {
    const reuiredData = column.map((ele, index) => {
      console.log(ele)
      if (ele.required != undefined) {
        if (ele.type == "date" || ele.type == "datetime") {
          let val2 = { required: ele.required, value: new Date() }
          return val2
        } else {
          let val2 = { required: ele.required, value: "" }
          return val2
        }
      } else {
        let val2 = { required: false, value: 0 }
        return val2
      }
    })
    setNewRequired(reuiredData)
    setShowAdd(true);
  }

  function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      );
    }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  const saveData = async (id) => {
    setSaveRestrict(false)
    // console.log(val, editedNotification)
    let mergedData = { ...val, ...editedNotification }
    // console.log("data1-->", mergedData)
    mergedData["timestamp"] = changeTimeZone(new Date(), 'Asia/Kolkata')
    // console.log(changeTimeZone(new Date(), 'Asia/Kolkata'))
    var result = requiredData.filter(function (v, i) {
      return (v.required && v.value === '')
    })
    // console.log(result)
    if (result.length > 0) {
      setSaveRestrict(true)
    } else {
      setSaveRestrict(false)
      // console.log("edited", mergedData)
      await axios
        .put(`${baseURL}/${id}`, mergedData, {
          headers: {
            'x-api-key': `${process.env.REACT_APP_APIKEY}`
          }
        })
        .then((response) => {
          setTimeout(() => {
            setShow(false)
            setSearchLbl([])
            setSearchKey([])
            setReload(!reload);
          }, 1000)
          setSaveRestrict(false)
          setTimeout(() => {
            updateTotalColumn()
          }, 100)
          const target = newData.find((obj) => obj.id === id)
          Object.assign(target, mergedData);
        })
        .catch((err) => console.log(err));
    }
    // setTimeout(()=>{
    //   addBtnRef.current.click()
    // }, 1500)
  };

  const addNewData = async () => {
    setSaveRestrict(false)
    var result = newRequired.filter(function (v, i) {
      return (v.required && v.value === '')
    })
    valuesNewAdd["timestamp"] = changeTimeZone(new Date(), 'Asia/Kolkata')
    // console.log(valuesNewAdd)
    if (result.length > 0) {
      setSaveRestrict(true)
    } else {
      setSaveRestrict(false)
      await axios
        .post(`${baseURL}/`, valuesNewAdd, {
          headers: {
            'x-api-key': `${process.env.REACT_APP_APIKEY}`
          }
        })
        .then((response) => {
          // console.log("response-->", response.data)
          setTimeout(() => {
            setShowAdd(false)
            setSearchLbl([])
            setSearchKey([])
            setReload(!reload);
            setValuesAdd({})
          }, 1000)
          setSaveRestrict(false)
          newData.push(response.data)
          setTimeout(() => {
            // setNewData([...data, response.data])
            updateTotalColumn()
            setValue3({})
            setValue4('')
          }, 100)
        })
        .catch((err) => {
          setValuesAdd({})
          setValue3({})
          setValue4('')
          console.log(err)
        });
      setShowAdd(false)
    }
  };

  const deleteData = async (id) => {
    await axios
      .delete(`${baseURL}/${id}`, {
        headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`
        }
      })
      .then((response) => {
        setShow(false);
        setReload(!reload);
      })
      .catch((err) => console.log(err));
  };

  function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      );
    }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = (apiData) => {
    const propsToDelete = [];
    // console.log(excelData, keys)

    excelData.forEach(function(v){ 
      // console.log(v)
      Object.keys(v).some(r=> {
        // console.log(r)
        if(r!=0){
          if(!keys.includes(r)){
            delete v[r]
          }
        }
      })
    });

    excelData.map((i, index) => {
      keys.map((ele) => {
        if (firstColumn[ele] == 0) {
          // console.log(i[ele])
          excelData[index][0] = i[ele]
          // delete excelData[index][ele]
          propsToDelete.push(ele)
        } else if (firstColumn[ele].type == 'array') {
          let key = firstColumn[ele]['name']
          // if (key != ele) {
          excelData[index][key] = i[ele].join(',')
          if (key != ele) {
            propsToDelete.push(ele)
          }
        } else if (firstColumn[ele].type == 'datetime') {
          let key = firstColumn[ele]['name']
          // if (key != ele) {
          excelData[index][key] = formatDateTime(i[ele])
          if (key != ele) {
            propsToDelete.push(ele)
          }
        } else if (firstColumn[ele].type == 'date') {
          let key = firstColumn[ele]['name']
          // if (key != ele) {
          excelData[index][key] = formatDate(i[ele])
          if (key != ele) {
            propsToDelete.push(ele)
          }
        }
        else {
          // console.log(ele)
          let key = firstColumn[ele]['name']
          // if (key != ele) {
          excelData[index][key] = i[ele]
          if (key != ele) {
            propsToDelete.push(ele)
          }
          // delete excelData[index][ele]
          // }
        }
      })
    })

    function removeDuplicates(arr) {
      return [...new Set(arr)];
    }

    const newArray = excelData.map(obj => {
      return _.omit(obj, removeDuplicates(propsToDelete));
    })

    // console.log(newArray)

    const ws = XLSX.utils.json_to_sheet(newArray.reverse())
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, tableName + fileExtension)
  }

  const showSearchField = (key) => {
    // console.log("here..", key)
    const indexKey = searchKey.indexOf(key);
    if (indexKey === -1) setSearchKey([...searchKey, key]);
    else {
      const splicedArrayKey = searchKey.filter((item) => item !== key);
      setSearchKey(splicedArrayKey);
      // console.log(key)
      if (key == 'id') {
        onSearch('', key, setNewData, updateTotalColumn, setNewExcelData)
      } else {
        onSearch('', key, setNewData, updateTotalColumn, setNewExcelData)
      }
    }
  }

  const showSearchLabel = (label) => {
    const indexKey = searchLbl.indexOf(label);
    if (indexKey === -1) setSearchLbl([...searchLbl, label]);
    else {
      const splicedArrayKey = searchLbl.filter((item) => item !== label);
      setSearchLbl(splicedArrayKey);
    }
  }

  const [dropDowns, setDropDowns] = useState([])
  const baseURLDropDown = `${process.env.REACT_APP_API_URL}/dropDowns`;

  React.useEffect(() => {
    axios.get(baseURLDropDown, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      response.data.shift();
      setDropDowns(response.data)
      // console.log(keys, column)
    });
    let data1 = {}
    column.map((data, index)=>{
      let key = keys[index]
      // console.log(data)
      if(data!=0){
        if( data.type=="datetime"){
          data1[key] = changeTimeZone(new Date(), 'Asia/Kolkata')
        }
        // setValuesAdd({
        //   ...data1,
        // });
        // console.log(val, valuesNewAdd)
      }
    })
  }, [reload]);

  // console.log("searchlbl", searchLbl)
  // React.useEffect(()=>{
  //   // console.log("erefrer.e....")
  // }, [])

  if (!data) return null;

  return (
    <>
      <Layout>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container style={{ marginTop: "80px" }}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1 style={{ color: "#0e5f6b" }}>{`${tableName}`}</h1>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
                variant="outlined"
                startIcon={<ImageSearchSharp />}
                onClick={() => handleAdvanceSearch()}
                style={{
                  borderRadius: "10px",
                  textTransform: "none",
                  fontWeight: "bolder",
                  // marginBottom: "5px",
                  marginRight: "10px",
                  height: "60px",
                  borderColor: '#0e5f6b',
                  borderWidth: 3
                }}
              >
              </Button> */}
              <label htmlFor="inputGroupFile">
                <input
                  style={{ display: 'none' }}
                  required
                  onChange={handleImport}
                  id="inputGroupFile"
                  name="inputGroupFile"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />

                <Button
                  classes={{ root: classes.MuiButtoncontainedSecondary }}
                  startIcon={<IosShareIcon />}
                  variant="contained" component="span">
                </Button>
              </label>
              <ThemeProvider theme={theme}>
                <Button
                  variant="outlined"
                  startIcon={<SystemUpdateAltOutlined />}
                  onClick={() => exportToExcel(newExcelData)}
                  style={{
                    borderRadius: "10px",
                    textTransform: "none",
                    fontWeight: "bolder",
                    // marginBottom: "5px",
                    marginRight: "10px",
                    height: "60px"
                  }}
                >
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => handleShowAdd()}
                  style={{
                    borderRadius: "10px",
                    textTransform: "none",
                    fontWeight: "bolder",
                    // marginBottom: "5px",
                    marginRight: "10px",
                    height: "60px"
                  }}
                >
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "20px" }}>
            {searchLbl &&
              searchLbl.map((item, index) => (
                <>
                  <TextField autoFocus style={{ marginRight: '10px' }} key={searchKey[index]}
                    onChange={(e) => {
                      if (searchKey[index] == 'id') {
                        onSearch(e.target.value.trim(), 'id', setNewData, updateTotalColumn, setNewExcelData)
                      } else {
                        onSearch(e.target.value.trim(), searchKey[index], setNewData, updateTotalColumn, setNewExcelData)
                      }
                    }} label={`Search ${item == 0 ? 'Id' : item}`} color="secondary" focused />
                </>
              ))}
          </Grid>
          <Grid style={{ marginTop: '20px' }}>
            {advanceSearch &&
              <>
               <FormControl style={{ width: '20%', marginRight: 20 }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={dataTypes[index]}
                    defaultValue={advanceSearchTypeSelected}
                    // label={column[index].name}
                    // required={column[index].required}
                    // // onChange={(e) => handleDataTypeChange(index, e, data)}
                    onChange={(e) => setAdvanceSearchSelected(e.target.value)
                    }
                  >
                     <MenuItem value="greaterThan">{"From (>=)"}</MenuItem>
                     <MenuItem value="lesserThan">{"To (<=)"}</MenuItem>
                     <MenuItem value="between">{"Between"}</MenuItem>
                  </Select>
                </FormControl>
                {advanceSearchTypeSelected=="greaterThan" || advanceSearchTypeSelected=="between" ? <TextField
                  style={{ width: "15%" }}
                  id="date"
                  label={'From Date'}
                  type="date"
                  InputProps={{
                    inputComponent: InputComponentStart
                  }}
                  onChange={(event) => {
                    // onChangeVal(index, formatDate(event.target.value), data, column[index].required)
                    setStartDate(event.target.value)
                    // console.log(event.target.value)
                  }}
                  defaultValue={new Date(startDate)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />: null}
                {advanceSearchTypeSelected=="lesserThan" || advanceSearchTypeSelected=="between" ? <TextField
                  style={{ width: "15%", marginLeft: '15px' }}
                  id="date"
                  label={'To Date'}
                  type="date"
                  InputProps={{
                    inputComponent: InputComponentEnd
                  }}
                  onChange={(event) => {
                    // onChangeVal(index, for
                    setEndDate(event.target.value)
                    // console.log(event.target.value)
                  }}
                  // defaultValue={new Date()}
                  defaultValue={new Date(endDate)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />: null}
                {/* <FormControl style={{ width: '20%', marginLeft: 20 }}>
                  <InputLabel id="demo-simple-select-label">DataType</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={dataTypes[index]}
                    defaultValue={advanceSearchTypeSelected}
                    // label={column[index].name}
                    // required={column[index].required}
                    // // onChange={(e) => handleDataTypeChange(index, e, data)}
                    onChange={(e) => setAdvanceSearchSelected(e.target.value)
                    }
                  >
                    {column.map(ele => {
                      return <MenuItem value={ele.type}>{ele.name}</MenuItem>
                    })}
                  </Select>
                </FormControl> */}
                <IconButton
                  color="inherit"
                  aria-label="Search"
                  onClick={() => {
                    setStartDate(undefined)
                    setEndDate(undefined)
                    onAdvanceSearch("clear", advanceSearchDataType)
                  }
                  }
                >
                  {startDate || endDate ? <Close /> : null}
                </IconButton>
                <Button
                  variant="outlined"
                  // startIcon={<ImageSearchSharp />}
                  onClick={() => onAdvanceSearch(setNewData, advanceSearchDataType)}
                  style={{
                    borderRadius: "10px",
                    textTransform: "none",
                    fontWeight: "bolder",
                    // marginBottom: "5px",
                    marginLeft: "30px",
                    height: "50px",
                    borderColor: '#0e5f6b'
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  // startIcon={<ImageSearchSharp />}
                  onClick={() => handleAdvanceSearch()}
                  style={{
                    borderRadius: "10px",
                    textTransform: "none",
                    fontWeight: "bolder",
                    // marginBottom: "5px",
                    marginLeft: "10px",
                    height: "50px",
                    borderColor: '#0e5f6b'
                  }}
                >
                  Close
                </Button>
              </>
            }
          </Grid>
          {isLoading && <CircularProgress style={{ justifyContent: 'center', display: 'flex', marginLeft: 650 }} color="success" />}
          <div>
            {isMobile ? (
              <TableContainer classes={{ root: classes.customTableContainer }} component={Paper} style={{ marginTop: "30px" }}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  sx={{ minWidth: 700 }}
                  stickyHeader
                >
                  <TableHead >
                    <TableRow>
                      {column.map((data, index) => {
                        // console.log("total values", totalValues)
                        return (
                          <>
                            {data != 0 ? data.visibleWeb ?
                              <>
                                <StyledTableCell
                                  style={{
                                    fontWeight: "bolder",
                                    backgroundColor: "#0e5f6b"
                                  }}
                                  align="left"
                                >
                                  <Button style={{
                                    width: '100%',
                                    display: 'inherit',
                                    alignItems: 'inherit',
                                    justifyContent: 'inherit',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                  }} onClick={() => {
                                    setSortAsc(!sortAsc)
                                    onSort(keys[index], setNewData, data.type)
                                  }
                                  } variant="text">{data.name}</Button>
                                  <br></br>
                                  {data.type == 'number' &&
                                    <IconButton
                                      ref={addBtnRef}
                                      color="inherit"
                                      aria-label="Total"
                                      onClick={() =>
                                        sumTotalColumn(keys[index])
                                      }
                                    >
                                      <AddBox />
                                    </IconButton>
                                  }
                                  {
                                    // data.type != 'number' && data.type != 'date' && data.type != 'datetime' &&
                                    // <IconButton
                                    //   color="inherit"
                                    //   aria-label="Search"
                                    //   onClick={() => {
                                    //     onSort(keys[index], setNewData)
                                    //   }}
                                    // >
                                    //   <VerticalAlignCenterIcon />
                                    // </IconButton>
                                  }
                                  <IconButton
                                    color="inherit"
                                    aria-label="Search"
                                    onClick={() => {
                                      showSearchField(keys[index])
                                      showSearchLabel(data.name)
                                    }
                                    }
                                  >
                                    {searchKey.indexOf(keys[index]) !== -1 ? <Close /> : <Search />}
                                  </IconButton>
                                  {data.type == 'float' &&
                                    <IconButton
                                      color="inherit"
                                      aria-label="Total"
                                      onClick={() =>
                                        sumTotalColumn(keys[index])
                                      }
                                    >
                                      <AddBox />
                                    </IconButton>
                                  }<br></br>
                                  {totalValues[keys[index]] != undefined && totalValues[keys[index]].toFixed(2)}
                                </StyledTableCell>
                              </> : null :
                              <StyledTableCell
                                style={{
                                  fontWeight: "bolder",
                                  backgroundColor: "#0e5f6b",
                                }}
                              >
                                <Button style={{
                                  width: '100%',
                                  display: 'inherit',
                                  alignItems: 'inherit',
                                  justifyContent: 'inherit',
                                  color: 'white',
                                  textTransform: 'capitalize',
                                }}
                                  onClick={() => {
                                    setSortAsc(!sortAsc)
                                    onSort("id", setNewData, data.type)
                                  }}
                                  variant="text">{data == 0 ? "Id" : data}</Button>
                                <IconButton
                                  color="inherit"
                                  aria-label="Search"
                                  onClick={() => {
                                    showSearchField('id')
                                    showSearchLabel(data)
                                  }
                                  }
                                >
                                  {searchKey.indexOf('id') !== -1 ? <Close /> : <Search />}
                                </IconButton>
                              </StyledTableCell>
                            }
                          </>
                        );
                      })}
                      <StyledTableCell
                        style={{
                          minWidth: "200px",
                          fontWeight: "bolder",
                          backgroundColor: "#0e5f6b",
                        }}
                      >
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {data.length > 0 ? (
                    <TableBody>
                      {data.map((val, key) => {
                        return (
                          <StyledTableRow key={key}>
                            {keys.map((data, index) => {
                              return (
                                <>
                                  {data != 'id' ? column[index].visibleWeb ?
                                    column[index].type == "date" ?
                                      <StyledTableCell component="th" scope="row">
                                        {formatDate(val[data])}
                                      </StyledTableCell> :
                                      column[index].type == "datetime" ?
                                        <StyledTableCell component="th" scope="row">
                                          {formatDateTime(val[data])}
                                        </StyledTableCell> :
                                        column[index].type == "array" ?
                                          <StyledTableCell component="th" scope="row">
                                            {val[data].toString()}
                                          </StyledTableCell> :
                                          <StyledTableCell component="th" scope="row">
                                            {val[data]}
                                          </StyledTableCell> : null :
                                    <StyledTableCell component="th" scope="row">
                                      {val[data]}
                                    </StyledTableCell>
                                  }
                                </>
                              );
                            })}
                            <StyledTableCell>
                              <IconButton
                                aria-label="Visibility"
                                onClick={() =>
                                  handleShow(`View ${tableName}`, val)
                                }
                              >
                                <Visibility />
                              </IconButton>
                              <IconButton
                                aria-label="EditRounded"
                                onClick={() =>
                                  handleShow(`Edit ${tableName}`, val)
                                }
                              >
                                <EditRounded />
                              </IconButton>
                              <IconButton
                                aria-label="Delete"
                                disabled={tableName == "User" ? true : false}
                                onClick={() =>
                                  handleShow(`Delete ${tableName}`, val)
                                }
                              >
                                <Delete />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={9}
                          style={{ textAlign: "center" }}
                        >
                          <h3>There are no records!</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <div>
                {data.length > 0 ? (
                  <div>
                    {data.map((val, keyindex) => {
                      return (
                        <div key={keyindex}>
                          <Card
                            sx={{ maxWidth: 345 }}
                            style={{ margin: "10px" }}
                          >
                            <CardContent>
                              {keys.map((data, index) => {
                                return (
                                  <>
                                    {data == 'id' ?
                                      <div>
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            color: "#0e5f6b",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {column[index]}
                                        </div>
                                        <div className="mb-1">{val[data]}</div>
                                      </div>
                                      :
                                      column[index].visibleMob ? <div>
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            color: "#0e5f6b",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {column[index].name}
                                        </div>
                                        <div className="mb-1">{val[data]}</div>
                                      </div> : null}
                                  </>
                                );
                              })}
                            </CardContent>
                            <CardActions xs={12}>
                              <IconButton
                                aria-label="Visibility"
                                onClick={() =>
                                  handleShow(`View ${tableName}`, val)
                                }
                              >
                                <Visibility />
                              </IconButton>
                              <IconButton
                                aria-label="EditRounded"
                                onClick={() =>
                                  handleShow(`Edit ${tableName}`, val)
                                }
                              >
                                <EditRounded />
                              </IconButton>
                              <IconButton
                                aria-label="Delete"
                                disabled={tableName == "User" ? true : false}
                                onClick={() =>
                                  handleShow(`Delete ${tableName}`, val)
                                }
                              >
                                <Delete />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Card>
                    <CardContent style={{ textAlign: "center" }}>
                      <h3>There are no records!</h3>
                    </CardContent>
                  </Card>
                )}
              </div>
            )}
          </div>
        </Box>

        {/* -------------------------------------------Meterial ui - Modal------------------------------------------- */}

        {val && (
          <Dialog
            classes={{ paper: classes.dialog }}
            open={show}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle
              style={{
                cursor: "pointer",
                color: "#0e5f6b",
                fontWeight: "bolder",
              }}
            >
              {title}
            </DialogTitle>
            {title === `View ${tableName}` && (
              <DialogContent>
                {keys.map((data, index) => {
                  return (
                    <div>
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-basic"
                        InputProps={{
                          readOnly: true,
                        }}

                        label={data == 'id' ? "Id" : column[index].name}
                        defaultValue={column[index].type == "date" ?
                          formatDate(val[data])
                          : column[index].type == "datetime" ?
                            formatDateTime(val[data])
                            : val[data]}
                      />
                    </div>
                  );
                })}
              </DialogContent>
            )}

            {title === `Edit ${tableName}` && (
              <DialogContent>
                <form>
                  {
                    <div>
                      {keys.map((data, index) => {
                        // console.log(column[index].type)
                        // console.log("dropDowns -->", dropDowns)
                        return (
                          <div>

                            {dropDowns.some(ele => ele.name == column[index].type) ?
                              (<FormControl fullWidth>
                                <InputLabel required={column[index].required} id="demo-simple-select-label">{column[index].name}</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  // value={dataTypes[index]}
                                  defaultValue={val[data]}
                                  label={column[index].name}
                                  required={column[index].required}
                                  // onChange={(e) => handleDataTypeChange(index, e, data)}
                                  onChange={(e) => onChangeVal(index, e.target.value, data, column[index].required)
                                  }
                                >
                                  {dropDowns.find(ele => ele.name == column[index].type).values.map(ele => {
                                    return <MenuItem value={ele}>{ele}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>)
                              :
                              column[index].type != 'boolean' ?
                                column[index].type == 'array' ?
                                  <TextField
                                    style={{ width: "100%" }}
                                    id="standard-basic"
                                    label={data == 'id' ? "Id" : column[index].name}
                                    type={column[index].type == 'string'}
                                    required={column[index].required}
                                    defaultValue={val[data]}
                                    InputProps={{
                                      readOnly: data === "id" ? true : false,
                                    }}
                                    inputProps={{
                                      max: '2050-01-01'
                                    }}
                                    onChange={(event) =>
                                      onChangeVal(index, event.target.value.split(','), data, column[index].required)
                                    }
                                  /> :
                                  column[index].type == 'date' ?
                                    (<TextField
                                      style={{ width: "100%" }}
                                      id="date"
                                      label={column[index].name}
                                      type="date"
                                      required={column[index].required}
                                      InputProps={{
                                        inputComponent: InputComponent
                                      }}
                                      onChange={(event) => {
                                        onChangeVal(index, new Date(event.target.value), data, column[index].required)
                                      }}
                                      defaultValue={val[data] ? new Date(val[data]): ""}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                    />) : column[index].type == 'datetime' ?
                                      (<TextField
                                        style={{ width: "100%" }}
                                        id="date"
                                        required={column[index].required}
                                        label={column[index].name}
                                        type="datetime-local"
                                        InputProps={{
                                          inputComponent: InputComponentDateTime
                                        }}
                                        onChange={(event) => {
                                          let d = new Date(event.target.value)
                                          onChangeVal(index, new Date(event.target.value), data, column[index].required)
                                        }}
                                        defaultValue={val[data] ? new Date(val[data]):column[index].name=="Timestamp"? moment(new Date()).format('DD-MMM-YYYY, h:mm a'): ""}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                      />) :
                                      <TextField
                                        style={{ width: "100%" }}
                                        id="standard-basic"
                                        label={data == 'id' ? "Id" : column[index].name}
                                        type={column[index].type == 'string' ? "text" : column[index].type == 'number' ? "number" : column[index].type == 'float' ? "number" : column[index].type == 'date' ? "date" : column[index].type == 'datetime' ? "datetime-local" : column[index].type == 'boolean' ? "text" : "text"}
                                        required={column[index].required}
                                        defaultValue={column[index].type == "date" ?
                                          new Date(val[data])
                                          : column[index].type == "datetime" ?
                                            new Date(val[data])
                                            : val[data]}
                                        InputProps={{
                                          readOnly: data === "id" ? true : false,
                                        }}
                                        inputProps={{
                                          max: '2050-01-01'
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        // helperText={error} // error message
                                        // error={!!error} // set to true to change the border
                                        onChange={(event) => {
                                          if (column[index].type == 'float') {
                                            var regex = /^\d*\.?\d{0,2}$/
                                            if (regex.test(event.target.value)) {
                                              setError("");
                                              // console.log("hererjkenfjkenv")
                                              onChangeVal(index, Number(event.target.value), data, column[index].required)
                                            } else {
                                              // console.log("hererjkenfjkenv")
                                              // toast.warning("Incorrect value..!")
                                            }
                                          } else if (column[index].type == 'number') {
                                            var regex = /^\d+$/
                                            var regex2 = /^[^.]+$/
                                            // console.log("Hre-", regex2.test(event.target.value))
                                            if (regex.test(event.target.value)) {
                                              setError("");
                                              // console.log("hererjkenfjkenv")
                                              onChangeVal(index, Number(event.target.value), data, column[index].required)
                                            } else {
                                              // console.log("hererjkenfjkenv")
                                              // toast.warning("Incorrect value..!")
                                            }
                                          } else {
                                            onChangeVal(index, event.target.value, data, column[index].required)
                                          }
                                        }
                                        }
                                      /> :
                                (
                                  <FormControl fullWidth>
                                    <InputLabel required={column[index].required} id="demo-simple-select-label">{column[index].name}</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      // value={dataTypes[index]}
                                      defaultValue={val[data]}
                                      label={column[index].name}
                                      required={column[index].required}
                                      // onChange={(e) => handleDataTypeChange(index, e, data)}
                                      onChange={(e) => onChangeVal(index, e.target.value, data, column[index].required)
                                      }
                                    >
                                      <MenuItem value={"True"}>True</MenuItem>
                                      <MenuItem value={"False"}>False</MenuItem>
                                    </Select>
                                  </FormControl>
                                )
                            }
                          </div>
                        );
                      })}
                    </div>
                  }
                </form>
                {saveRestrict && <Typography
                  className="text"
                  component="div"
                  variant="caption"
                  style={{ textAlign: 'center', color: "red" }}
                >
                  Enter all the required fields!
                </Typography>}
              </DialogContent>
            )}

            {title === `Delete ${tableName}` && (
              <DialogContent>
                <h5>Are you sure want to delete?</h5>
              </DialogContent>
            )}

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                autoFocus
                onClick={handleClose}
                color="primary"
                style={{ color: "white", backgroundColor: "#0e5f6b" }}
              >
                Close
              </Button>

              {title === `Edit ${tableName}` && (
                <Button
                  autoFocus
                  color="primary"
                  onClick={() => saveData(val.id)}
                  style={{ color: "white", backgroundColor: "#0e5f6b" }}
                >
                  Save Changes
                </Button>
              )}

              {title === `Delete ${tableName}` && (
                <Button
                  autoFocus
                  color="primary"
                  onClick={() => deleteData(val.id)}
                  style={{ color: "white", backgroundColor: "#0e5f6b" }}
                >
                  Delete
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}

        {showAdd && (
          <Dialog
            classes={{ paper: classes.dialog }}
            open={showAdd}
            onClose={handleAddClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle
              style={{
                cursor: "pointer",
                color: "#0e5f6b",
                fontWeight: "bolder",
              }}
            >
              <b>{`Add ${tableName}`}</b>
            </DialogTitle>
            <DialogContent>
              <form>
                {
                  <div>
                    {keys.map((data, index) => {
                      return (
                        <div>
                          {data != 'id' ? dropDowns.some(ele => ele.name == column[index].type) ?
                            (<FormControl fullWidth>
                              <InputLabel required={column[index].required} id="demo-simple-select-label">{column[index].name}</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={dataTypes[index]}
                                // defaultValue={val[data]}
                                label={column[index].name}
                                required={column[index].required}
                                // onChange={(e) => handleDataTypeChange(index, e, data)}
                                onChange={(e) => onAddChangeVal(index, e.target.value, data, column[index].required)
                                }
                              >
                                {dropDowns.find(ele => ele.name == column[index].type).values.map(ele => {
                                  return <MenuItem value={ele}>{ele}</MenuItem>
                                })}
                              </Select>
                            </FormControl>)
                            : column[index].type != 'boolean' ?
                              column[index].type == 'array' ?
                                <TextField
                                  style={{ width: "100%" }}
                                  id="standard-basic"
                                  label={data == 'id' ? "Id" : `${column[index].name}(Separate by comma)`}
                                  type={column[index].type == 'string'}
                                  required={column[index].required}
                                  InputProps={{
                                    readOnly: data === "id" ? true : false,
                                  }}
                                  inputProps={{
                                    max: '2050-01-01'
                                  }}
                                  onChange={(event) =>
                                    onAddChangeVal(index, event.target.value.split(','), data, column[index].required)
                                  }
                                /> :
                                column[index].type == 'date' ?
                                  (<TextField
                                    style={{ width: "100%" }}
                                    id="date"
                                    label={column[index].name}
                                    required={column[index].required}
                                    type="date"
                                    onChange={(event) => {
                                      // console.log("clicking here...",event.target.value)
                                      // setValue3(formatDate(event.target.value))
                                      value3[index + column[index].name] = event.target.value
                                      // console.log(moment(value3[index]).format('DD-MMM-YYYY'))
                                      // console.log(value3[index+column[index].name])
                                      onAddChangeVal(index, new Date(event.target.value), data, column[index].required)
                                    }}
                                    defaultValue={"DD-MM-YYYY"}
                                    value={value3[index + column[index].name] !== undefined ? moment(value3[index + column[index].name]).format('DD-MMM-YYYY') : ""}
                                    InputProps={{
                                      inputComponent: AddInputComponent
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />) : column[index].type == 'datetime' ?
                                    (<TextField
                                      style={{ width: "100%" }}
                                      id="date"
                                      label={column[index].name}
                                      required={column[index].required}
                                      type="datetime-local"
                                      defaultValue={"DD-MM-YYYY"}
                                      value={value4[index + column[index].name] !== undefined ? moment(value4[index + column[index].name]).format('DD-MMM-YYYY, h:mm a') :column[index].name=="Timestamp"? moment(new Date()).format('DD-MMM-YYYY,h:mm a'): ""}
                                      onChange={(event) => {
                                        value4[index + column[index].name] = event.target.value
                                        onAddChangeVal(index, new Date(event.target.value), data, column[index].required)
                                      }}
                                      InputProps={{
                                        inputComponent: AddInputComponentDateTime
                                      }}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                    />) :

                                    <TextField
                                      style={{ width: "100%" }}
                                      id="standard-basic"
                                      required={column[index].required}
                                      label={data == 'id' ? "Id" : column[index].name}
                                      type={column[index].type == 'string' ? "text" : column[index].type == 'float' ? "number" : column[index].type == 'number' ? "number" : column[index].type == 'date' ? "date" : column[index].type == 'datetime' ? "datetime-local" : column[index].type == 'boolean' ? "text" : "text"}
                                      defaultValue={data == "id" ? lastId + 1 : null}
                                      InputProps={{
                                        readOnly: data === "id" ? true : false,
                                      }}
                                      inputProps={{
                                        max: '2050-01-01'
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(event) => {
                                        if (column[index].type == 'float') {
                                          var regex = /^\d*\.?\d{0,2}$/
                                          if (regex.test(event.target.value)) {
                                            setError("");
                                            // console.log("hererjkenfjkenv")
                                            onAddChangeVal(index, Number(event.target.value), data, column[index].required)
                                          } else {
                                            // console.log("hererjkenfjkenv")
                                            toast.warning("Incorrect value..!")
                                          }
                                        } else if (column[index].type == 'number') {
                                          var regex = /^\d+$/
                                          if (regex.test(event.target.value)) {
                                            setError("");
                                            // console.log("hererjkenfjkenv")
                                            onAddChangeVal(index, Number(event.target.value), data, column[index].required)
                                          } else {
                                            // console.log("hererjkenfjkenv")
                                            toast.warning("Incorrect value..!")
                                          }
                                        } else {
                                          onAddChangeVal(index, event.target.value, data, column[index].required)
                                        }
                                      }

                                      }
                                    /> : (
                                <FormControl fullWidth>
                                  <InputLabel required={column[index].required} id="demo-simple-select-label">{column[index].name}</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value={dataTypes[index]}
                                    label="Data Type"
                                    required={column[index].required}
                                    // onChange={(e) => handleDataTypeChange(index, e, data)}
                                    onChange={(e) => onAddChangeVal(index, e.target.value, data, column[index].required)
                                    }
                                  >
                                    <MenuItem value={"True"}>True</MenuItem>
                                    <MenuItem value={"False"}>False</MenuItem>
                                  </Select>
                                </FormControl>
                              ) : null}
                        </div>
                      );
                    })}
                  </div>
                }
                {saveRestrict && <Typography
                  className="text"
                  component="div"
                  variant="caption"
                  style={{ textAlign: 'center', color: "red" }}
                >
                  Enter all the required fields!
                </Typography>}
              </form>
            </DialogContent>

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                autoFocus
                onClick={handleAddClose}
                color="primary"
                style={{
                  color: "white",
                  backgroundColor: "#0e5f6b",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => addNewData()}
                color="primary"
                style={{
                  color: "white",
                  backgroundColor: "#0e5f6b",
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Layout>
      <ToastContainer />
    </>
  );
}

export default CustomTable;
