import axios from "axios";
import "./table.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Import the default CSS
import "rsuite/dist/rsuite.min.css";
import { Layout } from "../LayoutComponent";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  Add,
  AddCircle,
  Close,
  Delete,
  EditRounded,
  UpdateOutlined,
  Visibility,
  DeleteSweep
} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import { useMediaQuery } from "@mui/material";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    minHeight: 500,
  },
  dialog: {
    width: "500px",
  },
});

function AdminTable({ linksData, endPoints, reload, setReload, tableName }) {
  const classes = useStyles();
  const location = useLocation();
  // console.log(endPoints)

  // for mobileview
  const matches = useMediaQuery("(min-width:600px)");
  // console.log("Matches ---> ", matches);
  const isMobile = matches;

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(`View ${tableName}`);
  const [showAdd, setShowAdd] = useState(false);
  const [val, setCurrentVal] = useState({});
  const [modalValues, setModalValues] = useState();
  const [modalKeys, setModalkeys] = useState();
  const [endPoint, setEndPoint] = useState();
  const [newAddData, setNewAddData] = useState(false);
  const [newKey, setNewKey] = useState("");
  const [dataTypes, setDataTypes] = useState([]);
  const [dataTypeKey, setDataTypeKey] = useState("")
  const [error, setError] = useState("");

  let columns = ["Endpoint", "Link", "Sidebar Title", "Heading", "IsAdmin", "Details", "View", "Edit", "Delete Data"];

  const handleClose = () => setShow(false);
  const handleAddClose = () => setShowAdd(false);
  const [isWebChecked, setIsWebChecked] = useState([])
  const [isMobChecked, setIsMobChecked] = useState([])
  const [isRequired, setIsRequired] = useState([])

  const handleDataTypeChange = (index, event, data) => {
    // setDataTypeKey(data)
    // setDataType(event.target.value);
    val[data].type = event.target.value
    const newState = dataTypes.map((obj, idx) => {
      if (index === idx) {
        return event.target.value
      }
      return obj
    });
    setDataTypes(newState)
  };

  // console.log("end points--->", endPoints)

  const [currentTableName, setCurrentTableName] = useState("")

  const handleShow = (str, val, end, tableName) => {
    setCurrentTableName(tableName)
    setTitle(str);
    setCurrentVal(val);
    setModalkeys(Object.keys(val));
    setModalValues(Object.values(val));
    let webChecked = Object.values(val).map((a) => a.visibleWeb)
    let mobChecked = Object.values(val).map((a) => a.visibleMob)
    let required = Object.values(val).map((a) => a.required)
    let dataType = Object.values(val).map(a => a.type)
    setDataTypes(dataType)
    setIsWebChecked(webChecked)
    setIsMobChecked(mobChecked)
    setIsRequired(required)
    setEndPoint(end.endpoint);
    setShow(true);
  };

  const handleAddPopupShow = () => setShow(true);

  const baseURL = `${process.env.REACT_APP_API_URL}/${endPoint}`;
  const baseURLDropDown = `${process.env.REACT_APP_API_URL}/dropDowns`;

  // const baseURL = `http://localhost:3000/${endPoint}`;

  var editedNotification = val;
  var addedData = val;

  const [dropDowns, setDropDowns] = useState([])

  React.useEffect(() => {
    axios.get(baseURLDropDown, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      response.data.shift();
      setDropDowns(response.data)
    });
  }, []);

  const saveData = async (id) => {
    setNewAddData(false);
    await axios
      .put(`${baseURL}/${id}`, editedNotification, {
        headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`
        }
      })
      .then((response) => {
        console.log(response)
        setShow(false);
        setReload(!reload);
      })
      .catch((err) => console.log(err));
  };

  const onAddChangeVal = (value, key) => {
    // addedData["id"] = lastId+1
    addedData[key] = value;
  };

  const addNewData = async () => {
    await axios
      .post(`${baseURL}/`, addedData)
      .then((response) => {
        setShowAdd(false);
        setReload(!reload);
      })
      .catch((err) => console.log(err));
  };

  const deleteData = async (id) => {
    await axios.delete(`${baseURL}/${id}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    })
      .then(response => {
        // console.log(response)
        setShow(false)
        setReload(!reload)
      })
      .catch(err => console.log(err))
  };

  const handleDeleteData = async () => {
    await axios.get(baseURL, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then(res => {
      res.data.map(ele => {
        if (ele.id != 0) {
          deleteData(ele.id)
        }
      })
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000)
    }).catch(err => console.log(err))
  }

  const onChangeVal = (value, key, newV) => {
    if (newV) {
      let newVal = {
        type: "string",
        required: false,
        name: value,
        visibleWeb: true,
        visibleMob: true
      }
      val[key] = newVal;
    } else {
      val[key].name = value;
      // console.log(val);
    }
  };

  useEffect(() => { }, [modalKeys]);

  const handleCheck = (key, index, visibleType, event) => {
    if (visibleType == 'web') {
      val[key].visibleWeb = event.target.checked;
      const newState = isWebChecked.map((obj, idx) => {
        if (index === idx) {
          return !obj
        }
        return obj
      });
      setIsWebChecked(newState);
    } else if (visibleType == 'mob') {
      val[key].visibleMob = event.target.checked;
      const newState = isMobChecked.map((obj, idx) => {
        if (index === idx) {
          return !obj
        }
        return obj
      });
      setIsMobChecked(newState);
    } else {
      val[key].required = event.target.checked;
      const newState = isRequired.map((obj, idx) => {
        if (index === idx) {
          return !obj
        }
        return obj
      });
      setIsRequired(newState);
    }
  }

  if (!linksData) return null;
  // console.log("data yhan -->", endPoints)


  return (
    <>
      <Layout>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container style={{ marginTop: "80px" }}>
            <h1 style={{ color: "#0e5f6b" }}>{`${tableName}`}</h1>
          </Grid>

          {/* <Button
            className="addBtn"
            variant="primary"
            onClick={() => setShowAdd(true)}
          >
            {`Add New ${tableName}`}
          </Button> */}

          <div>
            {isMobile ? (
              <TableContainer component={Paper} style={{ marginTop: "30px" }}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  sx={{ minWidth: 700 }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((data, key) => {
                        return (
                          <StyledTableCell
                            key={key}
                            style={{
                              fontWeight: "bolder",
                              backgroundColor: "#0e5f6b",
                            }}
                          >
                            {data}
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {endPoints &&
                      endPoints.map((ele, key) => {
                        return (
                          <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">
                              {ele.endpoint}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {ele.link}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {ele.name}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {ele.tableName}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {ele.isAdmin == true ? "True" : "False"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {JSON.stringify(linksData[key])}
                            </StyledTableCell>
                            <StyledTableCell>
                              <IconButton
                                aria-label="Visibility"
                                onClick={() =>
                                  handleShow(
                                    `View ${ele.tableName}`,
                                    linksData[key],
                                    ele,
                                    ele.tableName
                                  )
                                }
                              >
                                <Visibility />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell>
                              <IconButton
                                aria-label="EditRounded"
                                onClick={() =>
                                  handleShow(
                                    `Edit ${ele.tableName}`,
                                    linksData[key],
                                    ele,
                                    ele.tableName
                                  )
                                }
                              >
                                <EditRounded />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell>
                              <IconButton
                                aria-label="EditRounded"
                                // disabled
                                onClick={() =>
                                  handleShow(
                                    `Delete ${ele.tableName}`,
                                    linksData[key],
                                    ele,
                                    ele.tableName
                                  )
                                }
                              >
                                <DeleteSweep />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>
                {endPoints &&
                  endPoints.map((ele, key) => {
                    return (
                      <Card
                        key={key}
                        sx={{ maxWidth: 345 }}
                        style={{ margin: "10px" }}
                      >
                        <CardContent>
                          {columns.map((data, key1) => {
                            if (data === "Endpoint") {
                              return (
                                <div key={key1}>
                                  <strong style={{ color: "#0e5f6b" }}>
                                    {data}
                                  </strong>
                                  <div className="mb-1">{ele.endpoint}</div>
                                </div>
                              );
                            }
                            if (data === "Details") {
                              return (
                                <div key={key1}>
                                  <strong style={{ color: "#0e5f6b" }}>
                                    {data}
                                  </strong>
                                  <div className="mb-1 wrap-text">
                                    {JSON.stringify(linksData[key])}
                                  </div>
                                </div>
                              );
                            }
                            if (data === "View") {
                              return (
                                <div
                                  key={key1}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="Visibility"
                                    onClick={() =>
                                      handleShow(
                                        `View ${ele.tableName}`,
                                        linksData[key],
                                        ele,
                                        ele.tableName
                                      )
                                    }
                                  >
                                    <Visibility />
                                  </IconButton>
                                  <IconButton
                                    aria-label="EditRounded"
                                    onClick={() =>
                                      handleShow(
                                        `Edit ${ele.tableName}`,
                                        linksData[key],
                                        ele,
                                        ele.tableName
                                      )
                                    }
                                  >
                                    <EditRounded />
                                  </IconButton>
                                  <IconButton
                                    aria-label="EditRounded"
                                    onClick={() =>
                                      handleShow(
                                        `Delete ${ele.tableName}`,
                                        linksData[key],
                                        ele,
                                        ele.tableName
                                      )
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </div>
                              );
                            }
                          })}
                        </CardContent>
                      </Card>
                    );
                  })}
              </div>
            )}
          </div>
        </Box>

        {/* -------------------------------------------Meterial ui - Modal------------------------------------------- */}

        {val && (
          <Dialog
            classes={{ paper: classes.dialog }}
            open={show}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle
              style={{
                cursor: "pointer",
                color: "#0e5f6b",
                fontWeight: "bolder",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {title===`Delete ${currentTableName}`? `Delete ${currentTableName} data`: title}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {title === `Edit ${currentTableName}` && (
                    <IconButton
                      autoFocus
                      onClick={handleClose}
                      variant="primary"
                    >
                      <Close />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </DialogTitle>
            {title === `View ${currentTableName}` && (
              <DialogContent>
                {modalKeys &&
                  modalKeys.map((data, index) => {
                    return (
                      <div>
                        <TextField
                          style={{ width: "100%" }}
                          id="standard-basic"
                          InputProps={{
                            readOnly: true,
                          }}
                          label={data}
                          defaultValue={modalValues[index]?.name}
                        />
                      </div>
                    );
                  })}
              </DialogContent>
            )}

            {title === `Edit ${currentTableName}` && (
              <DialogContent >
                <form>
                  {
                    <div>
                      {modalKeys.map((data, index) => {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <TextField
                                style={{ width: "100%" }}
                                id="standard-basic"
                                label={data}
                                type="text"
                                defaultValue={data == "id" ? 0 : modalValues[index]?.name}
                                InputProps={{
                                  readOnly: data === "id" ? true : false,
                                }}
                                onChange={(event) =>
                                  onChangeVal(event.target.value, data, false)
                                }
                              />
                              {data != 'id' && <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Data Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={dataTypes[index]}
                                  label="Data Type"
                                  onChange={(e) => handleDataTypeChange(index, e, data)}
                                >
                                  <MenuItem value={"string"}>String</MenuItem>
                                  <MenuItem value={"datetime"}>DateTime</MenuItem>
                                  <MenuItem value={"number"}>Number</MenuItem>
                                  <MenuItem value={"date"}>Date</MenuItem>
                                  <MenuItem value={"float"}>Float</MenuItem>
                                  <MenuItem value={"boolean"}>Boolean</MenuItem>
                                  <MenuItem value={"array"}>Array</MenuItem>
                                  {dropDowns.map(ele => {
                                    return <MenuItem value={ele.name}>{ele.name}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>}
                              <IconButton
                                variant="primary"
                                onClick={() => saveData(0)}
                              >
                                <UpdateOutlined />
                              </IconButton>
                              <IconButton
                                disabled={data === "id" ? true : false}
                                variant="primary"
                                onClick={() => {
                                  delete modalKeys[index];
                                  delete modalValues[index];
                                  delete val[data];
                                  setTimeout(() => {
                                    saveData(0);
                                  }, 1500);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                            {data != 'id' && <div style={{ display: "flex" }}>
                              <FormControlLabel
                                control={<Checkbox checked={isWebChecked[index]} onChange={(e) => handleCheck(data, index, 'web', e)} />}
                                label="Web"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={isMobChecked[index]} onChange={(e) => handleCheck(data, index, 'mob', e)} />}
                                label="Mobile"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={isRequired[index]} onChange={(e) => handleCheck(data, index, 'req', e)} />}
                                label="Required"
                              />
                            </div>}
                          </>
                        );
                      })}
                    </div>
                  }
                </form>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <IconButton onClick={() => setNewAddData(true)}>
                    <AddCircle />
                  </IconButton>
                </div>

                {newAddData && (
                  <Grid style={{ display: "flex" }}>
                    <Grid item xs={8} md={8} xl={8}>
                      <TextField
                        style={{ marginInline: "10px" }}
                        label="Label"
                        type="text"
                        helperText={error} // error message
                        error={!!error} // set to true to change the border
                        // disabled={data === "id" ? true : false}
                        onChange={(event) => {
                          var regex = /^(?:[\da-z-]+)$/
                          if (regex.test(event.target.value)) {
                            setError("");
                            // console.log("hererjkenfjkenv")
                            setNewKey(event.target.value)
                          } else {
                            // console.log("hererjkenfjkenv")
                            setError("Small case, Forbidden character: %<>$'\"");
                          }
                        }}
                      />
                      <TextField
                        style={{ marginInline: "10px" }}
                        label="Value"
                        type="text"
                        // disabled={data === "id" ? true : false}
                        onChange={(event) =>
                          onChangeVal(event.target.value, newKey, true)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      xl={4}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignmentBaseline: "central",
                      }}
                    >
                      {/* <Button
                        variant="primary"
                        onClick={() => saveData("Id")}
                        style={{
                          color: "white",
                          backgroundColor: "#0e5f6b",
                          marginLeft: "20px",
                        }}
                      >
                        Add
                      </Button> */}

                      <IconButton
                        onClick={() => saveData(0)}
                        style={{ padding: "5px" }}
                      >
                        <Add />
                      </IconButton>

                      <IconButton
                        onClick={() => setNewAddData(false)}
                        style={{ padding: "5px" }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                    {/* <IconButton
                      disabled={data === "id" ? true : false}
                      variant="primary"
                      onClick={() => {
                        delete modalKeys[index];
                        delete modalValues[index];
                        delete val[data];
                        setTimeout(() => {
                          saveData("Id");
                        }, 1500);
                      }}
                    >
                      <Delete />
                    </IconButton> */}
                  </Grid>
                )}
              </DialogContent>
            )}

            {title === `Delete ${currentTableName}` && (
              <DialogContent>
                <h5>Are you sure want to delete all the data?</h5>
              </DialogContent>
            )}

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {title === `View ${currentTableName}` && (
                <Button
                  autoFocus
                  onClick={handleClose}
                  color="primary"
                  style={{ color: "white", backgroundColor: "#0e5f6b" }}
                >
                  Close
                </Button>
              )}

              {title === `Delete ${currentTableName}` && (
                <>
                  <Button
                    autoFocus
                    onClick={handleClose}
                    color="primary"
                    style={{ color: "white", backgroundColor: "#0e5f6b" }}
                  >
                    Close
                  </Button>
                  <Button
                    autoFocus
                    color="primary"
                    onClick={() => handleDeleteData()}
                    style={{ color: "white", backgroundColor: "#0e5f6b" }}
                  >
                    Delete
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        )}

        {/* {showAdd && (
          <Dialog
            classes={{ paper: classes.dialog }}
            open={showAdd}
            onClose={handleAddClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle
              style={{
                cursor: "pointer",
                color: "#0e5f6b",
              }}
            >
             
                <b>{`Add ${tableName}`}</b>
             
            </DialogTitle>
            <DialogContent>
              <form>
                {
                  <div>
                    {keys.map((data, index) => {
                      return (
                        <div>
                            <TextField
                              style={{ width: "100%" }}
                              id="standard-basic"
                              label={column[index]}
                              type="text"
                              // defaultValue={data=='id'?lastId+1: null}
                              InputProps={{
                                readOnly: data === "id" ? true : false,
                              }}
                              onChange={(event) =>
                                onAddChangeVal(event.target.value, data)
                              }
                            />
                        </div>
                      );
                    })}
                  </div>
                }
              </form>
            </DialogContent>

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                autoFocus
                onClick={handleAddClose}
                color="primary"
                style={{
                  color: "white",
                  backgroundColor: "#0e5f6b",
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => addNewData()}
                color="primary"
                style={{
                  color: "white",
                  backgroundColor: "#0e5f6b",
                }}
              >
                Save Changes 
              </Button>
            </DialogActions>
          </Dialog>
        )} */}
      </Layout>
    </>
  );
}

export default AdminTable;
