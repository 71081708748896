import React from "react";
import { Layout } from "../../Components/LayoutComponent/index";
import { Container, Grid, Paper, Typography } from "@material-ui/core";

function Home() {
  return (
    <>
      <Layout>
        <Container maxWidth="md">
          <div style={{ marginTop: "20%" }}>
            <Paper style={{ padding: "5%", margin: "5%" }}>
              <Grid>
                <Typography
                  className="wrap-text"
                  variant="h4"
                  align="center"
                  component="div"
                  gutterBottom
                  style={{ margin: "20%", color: "#0e5f6b" }}
                >
                  Welcome to Vajra!
                </Typography>
              </Grid>
            </Paper>
          </div>
        </Container>
      </Layout>
    </>
  );
}

export default Home;
