import React from "react";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import Routes from "./Routes";
import AppRoutes from "./Routes";
import "./App.css";

export default function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <div className="app"> */}
          {/* <Header /> */}
          <AppRoutes />
          {/* <Footer /> */}
          {/* </div> */}
        </PersistGate>
      </Provider>
    </>
  );
}
