import DynamicTable from "../Views/DynamicTable";
import Admin from "../Views/Admin";
import { selectIsAdmin, selectIsLoggedIn } from "../Redux/Slices/authSlice";
import Home from "../Views/Home";
import Login from "../Views/Login";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import UnAuthorised from "../Views/Unauthorized"
import Maintenance from "../Views/Maintenance";

const AppRoutes = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAdmin = useSelector(selectIsAdmin);

  const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_LINKS}`;
  const baseURLMaintenance = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_MAINTENANCE_URL}`;

  // const baseURL = " http://localhost:3000/links";

  const [data, setData] = useState(null);
  const [liveStatus, setLiveStatus] = useState("Maintenance")

  useEffect(() => {
    axios.get(baseURL, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      // console.log(response.data)
      setData(response.data);
    });
  }, []);

  useEffect(() => {
    axios.get(baseURLMaintenance, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`
      }
    }).then((response) => {
      // console.log("Here-->", response.data)
      let obj = response.data.find(o => o.application === "Vajra");
      // setData(response.data);
      // console.log("hre", obj)
      setLiveStatus(obj.mode)
    });
  }, []);

  return (
    <>
      <Routes>
        {liveStatus=='Live' ? 
        <><Route path="/vajra-login" element={isLoggedIn ? <Home/> : <Login />}></Route>
        <Route path="/" element={isLoggedIn ? <Home />: <UnAuthorised/>}></Route>
        <Route path="/admin" element={isLoggedIn ?  isAdmin=='Admin' ? <Admin />:<Home/>: <UnAuthorised/>}></Route>
        <Route path="*" element={<UnAuthorised />} />
        {data &&
          data.map((ele, index) => {
            return (
              <Route
                key={index}
                path={ele.link}
                element={
                  isLoggedIn ? ele.isAdmin ? isAdmin=='Admin'?
                  <DynamicTable
                    endpoint={ele.endpoint}
                    tableName={ele.tableName}
                  /> : <Home/>: 
                  <DynamicTable
                    endpoint={ele.endpoint}
                    tableName={ele.tableName}
                  /> :
                  <UnAuthorised />
                }
              ></Route>
            );
          })}</> : <Route path="/*" element={<Maintenance />} />}
      </Routes>
    </>
  );
};

export default AppRoutes;
