import { configureStore } from '@reduxjs/toolkit'
import authSlice from './Slices/authSlice'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

const reducers = combineReducers({
    auth: authSlice
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
  });

let persistor = persistStore(store);
  
export {store, persistor};