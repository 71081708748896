import React from "react";
// import { Sidebar } from "./Sidebar";
import { Footer } from "../Footer/index";
import { Header } from "../Header/index";

export const Layout = ({ children }) => {
  return (
    <>
      <Header></Header>
      <div
        style={{
          marginLeft: "70px",
          marginRight: "15px",
          marginTop: "70px",
          marginBottom: "70px",
        }}
      >
        {children}
      </div>
      <Footer style={{  marginBottom: "0px" }}></Footer>
    </>
  );
};
